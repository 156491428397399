import React from 'react';
import { Checkbox, Grid2, Typography, Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { ButtonComponent, DatePickerComponent, FileUploadComponent, InputComponent, SelectComponent } from '../..';
import { BookingModal, GoingBackModal } from '../';

import {
    AgeLimitService,
    AppContext,
    FileUploadService,
    MaintenanceService,
    PolicyCreateService
} from '../../../services';

function FormGroup(props) {
    const {
        title,
        data,
        handleChanges = () => {},
        type = 'PRINCIPAL',
        address = false,
        key,
        maintenance,
        min = new Date(),
        max = new Date(),
        state,
        tin,
        email,
        mobtel
    } = props;
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState({});
    const [copyEmail, setCopyEmail] = React.useState(false);
    const [copyMobTel, setCopyMobTel] = React.useState(false);

    React.useEffect(() => {
        if (copyEmail) {
            handleFormChange({ target: { value: email } }, 'email');
            onBlur({ email });
            setError((e) => ({ ...e, email: null }));
        }
    }, [email]);
    React.useEffect(() => {
        if (copyMobTel) {
            setForm((f) => ({ ...f, mobileNo: mobtel }));
            onBlur({ mobileNo: mobtel });
        }
    }, [mobtel]);

    React.useEffect(() => {
        if (data) {
            const { errors, ...others } = data;
            setForm(() => others);

            if (errors && Object.keys(errors).length) {
                setError(() => ({ ...errors }));
            }
        } else {
            setForm(() => ({}));
        }
    }, [data]);

    function onBlur(arg = {}) {
        handleChanges({ ...form, type, ...arg });
    }

    function handleFormChange(e, variable) {
        const {
            target: { value }
        } = e;

        setForm((f) => ({ ...f, [variable]: value.toUpperCase() }));
        setError((e) => ({ ...e, [variable]: undefined }));
    }

    return (
        <React.Fragment key={key}>
            <Grid2 size={{ xs: 12 }}>
                <Typography color="#fff" fontSize="16px">
                    {title}
                </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Typography color="#fff" fontSize="13px">
                    All fields marked with an asterisk (*) are required.
                </Typography>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <>
                    <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            First Name*
                                        </Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 12 }}
                                        sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                    >
                                        <InputComponent
                                            value={form.firstName}
                                            onChange={(e) => {
                                                setForm((f) => ({
                                                    ...f,
                                                    firstName: e.target.value.toUpperCase().replace(/[^A-Z\s]/g, '')
                                                }));
                                                setError((e) => ({ ...e, firstName: null }));
                                            }}
                                            textAlign="left"
                                            hideDecoration
                                            onBlur={onBlur}
                                            error={error?.firstName}
                                        />
                                    </Grid2>
                                </Grid2>
                            </>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            Middle Name
                                        </Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 12 }}
                                        sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                    >
                                        <InputComponent
                                            value={form.middleName}
                                            onChange={(e) =>
                                                setForm((f) => ({
                                                    ...f,
                                                    middleName: e.target.value.toUpperCase().replace(/[^A-Z\s]/g, '')
                                                }))
                                            }
                                            textAlign="left"
                                            hideDecoration
                                            onBlur={onBlur}
                                            error={error?.middleName}
                                        />
                                    </Grid2>
                                </Grid2>
                            </>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            Family Name*
                                        </Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 12 }}
                                        sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                    >
                                        <InputComponent
                                            value={form.lastName}
                                            onChange={(e) => {
                                                setForm((f) => ({
                                                    ...f,
                                                    lastName: e.target.value.toUpperCase().replace(/[^A-Z\s]/g, '')
                                                }));
                                                setError((e) => ({ ...e, lastName: null }));
                                            }}
                                            textAlign="left"
                                            hideDecoration
                                            onBlur={onBlur}
                                            error={error?.lastName}
                                        />
                                    </Grid2>
                                </Grid2>
                            </>
                        </Grid2>
                    </Grid2>
                </>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <>
                    <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            Birth Date*
                                        </Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 12 }}
                                        sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box sx={{ width: '100%' }}>
                                                <DatePicker
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiInputBase-root': {
                                                            fontSize: '0.85em',
                                                            height: '40px',
                                                            width: '100%'
                                                        },
                                                        '& .MuiFormControl-root': {
                                                            width: '100%',
                                                            fontSize: '0.85em',
                                                            height: '40px'
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            fontSize: '0.85em',
                                                            height: '40px'
                                                        },
                                                        background: '#fff'
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                    value={form?.dob ? dayjs(new Date(form.dob)) : null}
                                                    onChange={(e) => {
                                                        const newDate = dayjs(new Date(e));
                                                        const minDate = dayjs(new Date(min));
                                                        const maxDate = dayjs(new Date(max));

                                                        if (newDate.isValid()) {
                                                            if (newDate.isAfter(minDate) && newDate.isBefore(maxDate)) {
                                                                setForm((f) => ({
                                                                    ...f,
                                                                    dob: newDate.format('YYYY-MM-DD')
                                                                }));
                                                                setError((e) => ({ ...e, dob: null }));
                                                                onBlur({ dob: newDate.format('YYYY-MM-DD') });
                                                            } else {
                                                                setError((e) => ({
                                                                    ...e,
                                                                    dob: `Invalid birthdate.`
                                                                }));
                                                            }
                                                        } else {
                                                            setError((e) => ({
                                                                ...e,
                                                                dob: 'Invalid date format.'
                                                            }));
                                                        }
                                                    }}
                                                    minDate={dayjs(new Date(min))}
                                                    maxDate={dayjs(new Date(max))}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                        {error?.dob && (
                                            <Typography marginLeft="5px" fontSize="14px" color="error">
                                                {error?.dob}*
                                            </Typography>
                                        )}
                                    </Grid2>
                                </Grid2>
                            </>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            Gender at Birth*
                                        </Typography>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }} sx={{ borderRadius: '8px !important' }}>
                                        <SelectComponent
                                            hideDecoration
                                            rounderCorners
                                            value={form.gender}
                                            handleOnChanges={(val) => {
                                                setForm((f) => ({ ...f, gender: val }));
                                                setError((e) => ({ ...e, gender: null }));
                                                onBlur({ gender: val });
                                            }}
                                            selections={['MALE', 'FEMALE'].map((m) => ({
                                                label: m,
                                                value: m
                                            }))}
                                            error={error?.gender}
                                        />
                                    </Grid2>
                                </Grid2>
                            </>
                        </Grid2>

                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            Mobile Number*
                                        </Typography>
                                    </Grid2>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            width: '100%'
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: '#edf1f6',
                                                padding: '8px',
                                                borderRight: '1px solid rgba(0, 0, 0, 0.5)',
                                                alignSelf: 'start'
                                            }}
                                        >
                                            <span style={{ color: '#000', fontSize: '14px' }}>+63</span>
                                        </div>
                                        <div style={{ flex: 1, width: '100%' }}>
                                            <InputComponent
                                                value={form?.mobileNo ?? ''}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    if (/^\d{0,11}$/.test(newValue)) {
                                                        setForm((f) => ({ ...f, mobileNo: newValue }));
                                                        if (newValue.length > 0 && newValue.length < 10) {
                                                            setError((e) => ({
                                                                ...e,
                                                                mobileNo: 'Mobile number must be 10 or 11 digits long.'
                                                            }));
                                                        } else if (newValue.length >= 10 && newValue.length <= 11) {
                                                            setError((e) => ({ ...e, mobileNo: null }));
                                                            onBlur({ mobileNo: newValue });
                                                        }
                                                    } else {
                                                        // Set an error if input exceeds 11 digits or contains non-digit characters
                                                        // setError((e) => ({ ...e, mobileNo: 'Mobile number must be 10 or 11 digits long.' }));
                                                    }
                                                }}
                                                textAlign="left"
                                                hideDecoration
                                                error={error?.mobileNo}
                                                disabled={copyMobTel}
                                            />
                                        </div>
                                    </div>
                                </Grid2>
                                {mobtel && (
                                    <Grid2 size={{ xs: 12 }}>
                                        <>
                                            <Grid2 container sx={{ flexGrow: 1 }}>
                                                <Grid2>
                                                    <Checkbox
                                                        sx={{
                                                            color: '#fff',
                                                            '&.Mui-checked': {
                                                                color: '#fff'
                                                            }
                                                        }}
                                                        checked={!!copyMobTel}
                                                        onChange={() => {
                                                            setCopyMobTel(!copyMobTel);
                                                            setForm((f) => ({ ...f, mobileNo: mobtel }));
                                                            onBlur({ mobileNo: mobtel });
                                                            setError((e) => ({ ...e, mobileNo: null }));
                                                        }}
                                                    />
                                                </Grid2>
                                                <Grid2 sx={{ alignContent: 'center' }}>
                                                    <Typography color="#fff" fontSize="14px">
                                                        Same as Primary Traveler
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        </>
                                    </Grid2>
                                )}
                            </>
                        </Grid2>
                    </Grid2>
                </>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <>
                    <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <>
                                <Grid2 container sx={{ flexGrow: 1 }}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Typography color="#fff" fontSize="14px">
                                            E-mail Address*
                                        </Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 12 }}
                                        sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                    >
                                        <InputComponent
                                            value={form.email}
                                            error={error?.email || null}
                                            onChange={(e) => {
                                                handleFormChange(e, 'email');
                                                onBlur({ email: e.target.value });
                                                setError((e) => ({ ...e, email: null }));
                                            }}
                                            textAlign="left"
                                            hideDecoration
                                            disabled={copyEmail}
                                        />
                                    </Grid2>
                                </Grid2>
                                {email && (
                                    <Grid2 size={{ xs: 12 }}>
                                        <>
                                            <Grid2 container sx={{ flexGrow: 1 }}>
                                                <Grid2>
                                                    <Checkbox
                                                        sx={{
                                                            color: '#fff',
                                                            '&.Mui-checked': {
                                                                color: '#fff'
                                                            }
                                                        }}
                                                        checked={!!copyEmail}
                                                        onChange={() => {
                                                            setCopyEmail(!copyEmail);
                                                            handleFormChange({ target: { value: email } }, 'email');
                                                            onBlur({ email });
                                                            setError((e) => ({ ...e, email: null }));
                                                        }}
                                                    />
                                                </Grid2>
                                                <Grid2 sx={{ alignContent: 'center' }}>
                                                    <Typography color="#fff" fontSize="14px">
                                                        Same as Primary Traveler
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        </>
                                    </Grid2>
                                )}
                            </>
                        </Grid2>

                        {state?.geoCoverage !== 'D' ? (
                            <>
                                <Grid2 size={{ xs: 12, md: 4 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <>
                                                    <Grid2
                                                        container
                                                        justifyContent="space-between"
                                                        alignContent="center"
                                                    >
                                                        <Grid2>
                                                            <Typography color="#fff" fontSize="14px">
                                                                Passport Number*
                                                            </Typography>
                                                        </Grid2>

                                                        <Grid2>
                                                            <Typography color="#fff" fontSize="9px">
                                                                9-20 characters only
                                                            </Typography>
                                                        </Grid2>
                                                    </Grid2>
                                                </>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 12 }}
                                                sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                            >
                                                <InputComponent
                                                    value={form.idNo}
                                                    onChange={(e) => {
                                                        if (/^[a-zA-Z0-9]{0,20}$/.test(e?.target?.value)) {
                                                            handleFormChange(e, 'idNo');
                                                            setError((e) => ({ ...e, idNo: null }));
                                                            onBlur({ idNo: e.target.value.toUpperCase() });
                                                        }
                                                    }}
                                                    textAlign="left"
                                                    hideDecoration
                                                    error={error?.idNo}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>

                                <Grid2 size={{ xs: 12, md: 4 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <Typography color="#fff" fontSize="14px">
                                                    Upload your passport*
                                                </Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 12 }}
                                                sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                            >
                                                <FileUploadComponent
                                                    id={`${uuidv4()}-fileupload`}
                                                    handleOnChanges={async (e) => {
                                                        if (!e) return;

                                                        try {
                                                            const { data } = await MaintenanceService();
                                                            const fileFormatParam = data?.parameters?.find(
                                                                (param) => param.paramName === 'FILE.ALLOWED_FORMAT'
                                                            );

                                                            const allowedFormats =
                                                                fileFormatParam?.paramValueV?.split(',') || [];
                                                            const fileName = e.name;
                                                            const fileExtension = fileName
                                                                .split('.')
                                                                .pop()
                                                                .toLowerCase();

                                                            if (!allowedFormats.includes(fileExtension)) {
                                                                setForm((prevForm) => ({
                                                                    ...prevForm,
                                                                    file: null,
                                                                    idFileName: null
                                                                }));

                                                                setError((prevError) => ({
                                                                    ...prevError,
                                                                    idFileName: `Unsupported file format. Allowed formats: ${allowedFormats.join(
                                                                        ', '
                                                                    )}`
                                                                }));

                                                                return;
                                                            }

                                                            const mimeTypesMap = {
                                                                png: '89504e47',
                                                                jpeg: 'ffd8ffe1',
                                                                jpg: 'ffd8ffe0',
                                                                bmp: '424d',
                                                                pdf: '25504446'
                                                            };

                                                            const mimeTypes = Object.fromEntries(
                                                                allowedFormats
                                                                    .filter((format) => mimeTypesMap[format])
                                                                    .map((format) => [
                                                                        mimeTypesMap[format],
                                                                        `application/${format}`
                                                                    ])
                                                            );

                                                            const fileReader = new FileReader();
                                                            fileReader.onloadend = () => {
                                                                const uint = new Uint8Array(fileReader.result).subarray(
                                                                    0,
                                                                    4
                                                                );
                                                                let fileSignature = '';
                                                                for (let i = 0; i < uint.length; i++) {
                                                                    fileSignature += uint[i].toString(16);
                                                                }

                                                                const detectedMimeType =
                                                                    mimeTypes[fileSignature] || 'unknown';

                                                                if (
                                                                    !Object.values(mimeTypes).includes(detectedMimeType)
                                                                ) {
                                                                    setForm((prevForm) => ({
                                                                        ...prevForm,
                                                                        file: null,
                                                                        idFileName: null
                                                                    }));

                                                                    setError((prevError) => ({
                                                                        ...prevError,
                                                                        idFileName:
                                                                            'Unsupported file format detected, please upload a different file.'
                                                                    }));

                                                                    return;
                                                                }

                                                                setForm((prevForm) => ({
                                                                    ...prevForm,
                                                                    file: e,
                                                                    idFileName: fileName
                                                                }));

                                                                onBlur({
                                                                    file: e,
                                                                    idFileName: fileName
                                                                });

                                                                setError((prevError) => ({
                                                                    ...prevError,
                                                                    idFileName: null
                                                                }));
                                                            };

                                                            fileReader.onerror = () => {
                                                                setError((prevError) => ({
                                                                    ...prevError,
                                                                    idFileName:
                                                                        'Error reading the file. Please try again.'
                                                                }));
                                                            };

                                                            fileReader.readAsArrayBuffer(e);
                                                        } catch (err) {
                                                            console.error(
                                                                'Error fetching parameters from MaintenanceService:',
                                                                err
                                                            );
                                                            setError((prevError) => ({
                                                                ...prevError,
                                                                idFileName:
                                                                    'Failed to validate file format. Please try again later.'
                                                            }));
                                                        }
                                                    }}
                                                    error={error?.idFileName}
                                                    fileUploaded={!!form.idFileName}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>
                            </>
                        ) : null}

                        {tin ? (
                            <Grid2 size={{ xs: 12, md: 4 }}>
                                <>
                                    <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                                        <Grid2 size={{ xs: 12, md: 12 }}>
                                            <>
                                                <Grid2 container sx={{ flexGrow: 1 }}>
                                                    <Grid2 size={{ xs: 12 }}>
                                                        <>
                                                            <Grid2
                                                                container
                                                                justifyContent="space-between"
                                                                alignContent="center"
                                                            >
                                                                <Grid2>
                                                                    <Typography color="#fff" fontSize="14px">
                                                                        Taxpayer Identification Number*
                                                                    </Typography>
                                                                </Grid2>

                                                                <Grid2>
                                                                    <Typography color="#fff" fontSize="9px">
                                                                        14 digits only
                                                                    </Typography>
                                                                </Grid2>
                                                            </Grid2>
                                                        </>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 12 }}
                                                        sx={{
                                                            borderRadius: '8px !important',
                                                            overflow: 'hidden !important'
                                                        }}
                                                    >
                                                        <InputComponent
                                                            value={form.tin}
                                                            onChange={(e) => {
                                                                setForm((f) => ({
                                                                    ...f,
                                                                    tin: e.target.value
                                                                        .replace(/[^0-9]/g, '')
                                                                        .slice(0, 14)
                                                                }));
                                                                setError((e) => ({ ...e, tin: null }));
                                                            }}
                                                            textAlign="left"
                                                            hideDecoration
                                                            onBlur={onBlur}
                                                            error={error?.tin || null}
                                                            type="text"
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </>
                                        </Grid2>
                                    </Grid2>
                                </>
                            </Grid2>
                        ) : null}
                    </Grid2>
                </>
            </Grid2>

            {/* END OF GROUP */}

            {/* GROUP 2 */}
            {address ? (
                <>
                    <Grid2 size={{ xs: 12 }} sx={{ marginTop: '60px' }}>
                        <>
                            <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                                <Grid2 size={{ xs: 12, md: 9 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <Typography color="#fff" fontSize="14px">
                                                    House # & Street / Unit #, Tower, Building & Street*
                                                </Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 12 }}
                                                sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                            >
                                                <InputComponent
                                                    value={form.address}
                                                    onChange={(e) => {
                                                        setForm((f) => ({ ...f, address: e.target.value }));
                                                        onBlur({ address: e.target.value });
                                                        setError((e) => ({ ...e, address: null }));
                                                    }}
                                                    textAlign="left"
                                                    hideDecoration
                                                    onBlur={onBlur}
                                                    error={error?.address}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                        <>
                            <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                                <Grid2 size={{ xs: 12, md: 4 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <Typography color="#fff" fontSize="14px">
                                                    Province*
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 12 }} sx={{ borderRadius: '8px !important' }}>
                                                <InputComponent
                                                    value={form?.province}
                                                    onChange={(e) => {
                                                        const {
                                                            target: { value }
                                                        } = e;
                                                        const { provinceName, provinceCd } = value;
                                                        setForm((f) => ({
                                                            ...f,
                                                            province: typeof value === 'string' ? value : provinceName,
                                                            provinceCd,
                                                            city: '',
                                                            cityCd: '',
                                                            barangay: '',
                                                            zipCode: ''
                                                        }));
                                                        setError((e) => ({ ...e, province: null }));
                                                    }}
                                                    textAlign="left"
                                                    hideDecoration
                                                    onBlur={() => {
                                                        if (!form?.provinceCd) {
                                                            onBlur({
                                                                province: '',
                                                                provinceCd: '',
                                                                city: '',
                                                                cityCd: '',
                                                                barangay: '',
                                                                zipCode: ''
                                                            });
                                                        } else {
                                                            onBlur({
                                                                province: form?.province,
                                                                provinceCd: form?.provinceCd
                                                            });
                                                        }
                                                    }}
                                                    selections={maintenance?.province || []}
                                                    selector="provinceName"
                                                    error={error?.province}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>

                                <Grid2 size={{ xs: 12, md: 4 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <Typography color="#fff" fontSize="14px">
                                                    City/Municipality*
                                                </Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 12 }}
                                                sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                            >
                                                <InputComponent
                                                    value={form.city}
                                                    disabled={!form.provinceCd}
                                                    onChange={(e) => {
                                                        const {
                                                            target: { value }
                                                        } = e;
                                                        const { cityName, cityCd } = value;
                                                        setForm((f) => ({
                                                            ...f,
                                                            city: typeof value === 'string' ? value : cityName,
                                                            cityCd,
                                                            barangay: '',
                                                            zipCode: ''
                                                        }));
                                                        setError((e) => ({ ...e, city: null }));
                                                    }}
                                                    textAlign="left"
                                                    hideDecoration
                                                    onBlur={() => {
                                                        if (!form?.cityCd) {
                                                            onBlur({
                                                                city: '',
                                                                cityCd: '',
                                                                barangay: '',
                                                                zipCode: ''
                                                            });
                                                        } else {
                                                            onBlur({
                                                                city: form?.city,
                                                                cityCd: form?.cityCd,
                                                                barangay: '',
                                                                zipCode: ''
                                                            });
                                                        }
                                                    }}
                                                    selections={(maintenance?.city || []).filter(
                                                        (f) => f.provinceCd === form.provinceCd
                                                    )}
                                                    selector="cityName"
                                                    error={error?.city}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                        <>
                            <Grid2 container sx={{ flexGrow: 1 }} spacing={3}>
                                <Grid2 size={{ xs: 12, md: 4 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <Typography color="#fff" fontSize="14px">
                                                    Barangay*
                                                </Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 12 }}
                                                sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                            >
                                                <InputComponent
                                                    disabled={!form.cityCd}
                                                    value={form.barangay}
                                                    onChange={(e) => {
                                                        const {
                                                            target: { value }
                                                        } = e;
                                                        const { postalDesc, bmCode } = value;
                                                        setForm((f) => ({
                                                            ...f,
                                                            barangay: typeof value === 'string' ? value : postalDesc,
                                                            zipCode: bmCode
                                                        }));
                                                        setError((e) => ({ ...e, barangay: null, zipCode: null }));
                                                    }}
                                                    textAlign="left"
                                                    hideDecoration
                                                    onBlur={() => {
                                                        if (!form?.zipCode) {
                                                            onBlur({
                                                                barangay: '',
                                                                zipCode: ''
                                                            });
                                                        } else {
                                                            onBlur({
                                                                barangay: form?.barangay,
                                                                zipCode: form?.zipCode
                                                            });
                                                        }
                                                    }}
                                                    selections={(maintenance?.barangay || []).filter(
                                                        (f) =>
                                                            f.provinceCd === form.provinceCd && f.cityCd === form.cityCd
                                                    )}
                                                    selector="postalDesc"
                                                    error={error?.barangay}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>

                                <Grid2 size={{ xs: 12, md: 4 }}>
                                    <>
                                        <Grid2 container sx={{ flexGrow: 1 }}>
                                            <Grid2 size={{ xs: 12 }}>
                                                <Typography color="#fff" fontSize="14px">
                                                    ZIP code*
                                                </Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 12 }}
                                                sx={{ borderRadius: '8px !important', overflow: 'hidden !important' }}
                                            >
                                                <InputComponent
                                                    disabled
                                                    value={form.zipCode}
                                                    textAlign="left"
                                                    hideDecoration
                                                    onBlur={onBlur}
                                                    error={error?.zipCode}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </>
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>
                </>
            ) : null}
        </React.Fragment>
    );
}

function FormScaffold() {
    const [openModal, setOpenModal] = React.useState(false);
    const { state, form, setForm, maintenance, setConfig, config } = React.useContext(AppContext);
    const [openGoingBackModal, setOpenGoingBackModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const componentRef = React.useRef();

    React.useEffect(() => {
        return () => setLoading(false);
    }, []);

    React.useEffect(() => {
        if (componentRef.current) {
            componentRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, []);

    function validate() {
        const formFields = [
            'firstName',
            //'middleName',
            'lastName',
            'gender',
            'mobileNo',
            'email',
            // 'tin',
            'type',
            'dob'
        ];

        const errorMessages = {
            firstName: 'Field is required.',
            middleName: 'Field is required.',
            lastName: 'Field is required.',
            dob: 'Field is required.',
            gender: 'Field is required.',
            mobileNo: 'Field is required.',
            email: 'Field is invalid or required.',
            idNo: 'Passport Number must be between 9-20 characters.',
            tin: 'Taxpayer Identification Number is invalid.',
            type: 'Field is required.',
            idType: 'Field is required.',
            idFileName: 'Field is required.',
            address: 'Field is required.',
            province: 'Field is required.',
            city: 'Field is required.',
            barangay: 'Field is required.',
            zipCode: 'Field is required.'
        };

        const validations = {
            firstName: (v) => !!v,
            middleName: (v) => !!v,
            lastName: (v) => !!v,
            dob: (v) => !!v,
            gender: (v) => !!v,
            mobileNo: (v) => !!v && /^\d{0,11}$/.test(v),
            email: (v) => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v),
            idNo: (v) => !!v && /^[a-zA-Z0-9]{9,20}$/.test(v),
            tin: (v) => /^\d{14}$/.test(v),
            type: (v) => !!v,
            idType: (v) => !!v,
            idFileName: (v) => !!v,
            address: (v) => !!v,
            province: (v) => !!v,
            city: (v) => !!v,
            barangay: (v) => !!v,
            zipCode: (v) => !!v
        };

        let errorNo = 0;
        let _form = form.slice();

        if (!_form.length) _form.push({ type: 'PRINCIPAL' });
        console.log('Initial Form Data: ', _form);
        for (let i = 0; i < _form.length; i++) {
            const { type: _formType } = _form[i];
            const _errors = {};
            let validationFormFields = [].concat(formFields);

            if (!!(state?.type !== 'B') && i === 0) validationFormFields = validationFormFields.concat(['tin']);

            if (state?.geoCoverage !== 'D') {
                validationFormFields = validationFormFields.concat(['idNo', 'idFileName']);
            }

            // meaning no changes on form
            if (!_formType) {
                validationFormFields = validationFormFields.concat([
                    'address',
                    'province',
                    'city',
                    'barangay',
                    'zipCode'
                ]);

                if (i === 0) {
                    validationFormFields = validationFormFields.concat(['tin']);
                }

                errorNo += 1;
                for (let f of validationFormFields) {
                    _errors[f] = errorMessages[f];
                }

                _form[i] = Object.assign({}, _form[i], { errors: _errors });
                continue;
            }

            if (_formType === 'PRINCIPAL' && state?.type !== 'B') {
                validationFormFields = validationFormFields.concat([
                    'address',
                    'province',
                    'city',
                    'barangay',
                    'zipCode'
                ]);
            }

            // validate basic
            for (let f of validationFormFields) {
                const validator = validations[f];
                if (!validator(_form[i][f])) {
                    errorNo += 1;
                    _errors[f] = errorMessages[f];
                }
            }

            _form[i] = Object.assign({}, _form[i] || {}, { errors: _errors });
        }

        if (errorNo) {
            setForm(() => _form);
            throw new Error('Validation error');
        }

        return true;
    }

    function handleReview() {
        try {
            const isValid = validate();
            if (!isValid) throw new Error('FORM INVALID');

            setOpenModal(true);
        } catch (error) {
            console.log(error);
        }
    }

    async function submit() {
        if (loading) return;
        const {
            type: travelTypeCd,
            groupSize: travelGroupSizeCd,
            plan: travelPlanCd,
            geoCoverage: travelGeoCoverageCd,
            transportMode: travelTransportModeCd,
            durationTypeCd: durationTypeCd,
            maxTripDuration: durationSelectionCd,
            durationDays: duration,
            startDate,
            endDate,
            company: companyCd,
            destination: countryCd
        } = state;
        let _durationTypeCd;
        let _durationSelectionCd;
        let _duration;

        if (!durationTypeCd) {
            const _d = maintenance?.travelDurationTypePerDetails.find(
                (f) =>
                    f.travelTypeCd === travelTypeCd &&
                    f.travelGroupSizeCd === travelGroupSizeCd &&
                    f.travelPlanCd === travelPlanCd &&
                    f.travelGeoCoverageCd === travelGeoCoverageCd &&
                    f.travelTransportModeCd === travelTransportModeCd
            );

            _durationTypeCd = _d.durationTypeCd;
        }

        if (!durationSelectionCd) {
            const _ds = maintenance?.travelDurationSelection.find((f) => f.durationTypeCd === _durationTypeCd);
            _durationSelectionCd = _ds.durationSelectionCd;
            _duration = _ds.durationLimit || 365;
        }

        const record = {
            travelTypeCd,
            travelPlanCd,
            travelGroupSizeCd,
            travelGeoCoverageCd,
            travelTransportModeCd,
            durationTypeCd: durationTypeCd ? durationTypeCd : _durationTypeCd,
            durationSelectionCd: durationSelectionCd ? durationSelectionCd : _durationSelectionCd,
            duration: duration ? duration : startDate ? moment(endDate).diff(moment(startDate), 'days') : _duration,
            companyCd,
            countryCd,
            inceptionDate: startDate ? new Date(startDate).toISOString() : new Date().toISOString(),
            expiryDate: endDate
                ? new Date(endDate).toISOString()
                : new Date(moment().add(duration, 'days')).toISOString(),
            clients: form
        };

        try {
            setLoading(true);
            const _response = await PolicyCreateService(record);
            const { presignedUrls = [], Location, error } = _response;

            if (error) throw new Error('Error creation');

            for (let i = 0; i < presignedUrls.length; i++) {
                if (form[i].file) await FileUploadService(presignedUrls[i], form[i].file);
            }

            setConfig((c) => ({ ...c, page: 'transaction', applicationStatus: Location ? '' : 'WOP' }));
            window.scrollTo(0, 0);

            if (Location) {
                window.location.replace(Location);
            }
        } catch (error) {
            console.error(error, 'Error submitting form');
        } finally {
            setLoading(false);
        }
    }

    function handleBlur(index, data = {}) {
        const _form = form.slice();
        _form[index] = data;
        setForm(() => _form);
    }

    function handleGoBack() {
        setOpenGoingBackModal(true);
    }

    function confirmGoBack() {
        setForm([]);
        setOpenGoingBackModal(false);
        setConfig((c) => ({ ...c, page: 'landing', agreed: false }));
    }

    return (
        <Grid2 ref={componentRef} container columnSpacing={6} rowSpacing={3} justifyContent="center">
            <Grid2 size={{ xs: 12 }}>
                <Typography variant="h4" color="#fff">
                    Who's going on the trip?
                </Typography>
            </Grid2>

            {FormGroup({
                title: 'Primary Traveler',
                data: form[0],
                address: state?.type !== 'B' ? true : false,
                handleChanges: (e) => {
                    handleBlur(0, e);
                },
                maintenance: maintenance,
                min: AgeLimitService('ADULT', maintenance).min,
                max: AgeLimitService('ADULT', maintenance).max,
                state,
                tin: !!(state?.type !== 'B')
            })}

            {(state?.totalCompanions || []).map((client, i) =>
                FormGroup({
                    title: `Secondary Traveler ${i + 1} (${client})`,
                    data: form[i + 1],
                    type: 'COMPANION',
                    handleChanges: (e) => {
                        handleBlur(i + 1, e);
                    },
                    min: AgeLimitService(client, maintenance).min,
                    max: AgeLimitService(client, maintenance).max,
                    state,
                    tin: false,
                    email: form[0]['email'] || '',
                    mobtel: form[0]['mobileNo'] || ''
                })
            )}

            <Grid2 size={{ xs: 12, md: 10 }} marginTop="60px">
                <>
                    <Grid2 container>
                        <Grid2 size={{ xs: 2, md: 1 }}>
                            <Checkbox
                                sx={{
                                    color: '#fff',
                                    '&.Mui-checked': {
                                        color: '#fff'
                                    }
                                }}
                                checked={!!config.agreed}
                                onChange={() => setConfig((c) => ({ ...c, agreed: !c.agreed }))}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 9, md: 10 }}>
                            <Typography color="#fff" fontSize="24px">
                                I have read, understood and agree to the{' '}
                                <u>
                                    <a
                                        href="https://bit.ly/BPIMS_StakeholderCharter"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ fontWeight: '600', color: '#fff' }}
                                    >
                                        BPI MS Customer Charter
                                    </a>
                                </u>
                                , the BPI MS{' '}
                                <u>
                                    <a
                                        href="https://bpims.com/privacy-policy/"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ fontWeight: '600', color: '#fff' }}
                                    >
                                        Data Privacy Policy
                                    </a>
                                </u>
                                , the{' '}
                                <u>
                                    <a
                                        href="https://bit.ly/AyalaTravelTU"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ fontWeight: '600', color: '#fff' }}
                                    >
                                        Terms of Use
                                    </a>
                                </u>{' '}
                                of this microsite, and the{' '}
                                <u>
                                    <a
                                        href="https://bit.ly/AyalaTravelTC"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ fontWeight: '600', color: '#fff' }}
                                    >
                                        Terms and Conditions
                                    </a>
                                </u>{' '}
                                of my insurance plan, issued and underwritten by BPI/MS Insurance Corporation.
                            </Typography>
                        </Grid2>
                    </Grid2>
                </>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 12 }} sx={{ marginTop: '60px' }}>
                <>
                    <Grid2 container justifyContent="center" spacing={2}>
                        <Grid2 size={{ lg: 4, xs: 12 }}>
                            <ButtonComponent label="PREVIOUS" handleClick={handleGoBack} />
                        </Grid2>
                        <Grid2 size={{ lg: 4, xs: 12 }}>
                            <ButtonComponent
                                disabled={!config.agreed}
                                color="#fff"
                                background="#ff6002"
                                label="REVIEW BOOKING"
                                handleClick={handleReview}
                            />
                        </Grid2>
                    </Grid2>
                </>
            </Grid2>

            <BookingModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                onSubmit={submit}
                loading={loading}
            />

            <GoingBackModal
                open={openGoingBackModal}
                cancelGoBack={() => setOpenGoingBackModal(false)}
                confirmGoBack={confirmGoBack}
            />
        </Grid2>
    );
}

export default FormScaffold;
